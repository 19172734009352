require('./bootstrap');
require('./livechat');

$(document).ready(function() {
    $("#scroll-button").click(function () {
        console.log('ran')
        $('html,body').animate({
                scrollTop: $("#home-demo").offset().top
            },
            'slow');
    });
});
